import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import SocialLogo from '../images/SG-SocialLogo.png';

const SEO: React.SFC<SEOProps> = ({
  title,
  description,
  lang,
  keywords,
  meta,
}: SEOProps) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaTitle = title || data.site.siteMetadata.title;
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaLang = lang || 'en';
        
        return (
          <Helmet
            htmlAttributes={{
              lang: metaLang,
            }}
            title={metaTitle}
            titleTemplate={ title ? `%s | ${data.site.siteMetadata.title}` : `${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `theme-color`,
                content: '#424B4D'
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:url`,
                content: data.site.siteMetadata.url,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: `${data.site.siteMetadata.url}${SocialLogo}`,
              },
              {
                property: 'og:image:width',
                content: `1200`
              },
              {
                property: 'og:image:height',
                content: `630`
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:site`,
                content: `@StilsonGreene`
              },
              {
                name: `twitter:creator`,
                content: `@BaristaLabs`
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta && meta.length > 0 ? meta : [])}
          />
        );
      }}
    />
  );
};

export default SEO;

interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
  meta?: any[];
  keywords?: string[];
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
  }
`;
