import React from 'react';
import cn from 'classnames';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      width: '100%',
      height: '200px',
      paddingTop: '16px',
      minHeight: '150px',
      backgroundColor: '#231F20',
    },
    footerContents: {
      textAlign: 'center',
      fontSize: '13px',
      fontWeight: 550,
      color: 'white',
      lineHeight: '16px',
    }
  });

const Footer: React.SFC<FooterProps> = ({ classes }) => (
  <div className={cn('Site-footer', classes.footer)} role="contentinfo">
    <Grid
      className={classes.footerContents}
      container
      justify="center"
      alignItems="flex-start"
      spacing={0}
    >
      <Grid item xs={12}>Stilson Greene Graphic Design and Illustration</Grid>
      <Grid item xs={12}>By Appointment Only</Grid>
      <Grid item xs={12}>703  727  4359</Grid>
      <Grid item xs={12}>all artwork and imagery © Stilson Greene or client</Grid>
      <Grid item xs={12}>Web Design: <a href="https://www.baristalabs.io/">BaristaLabs, LLC</a></Grid>
    </Grid>
  </div>
);

interface FooterProps extends WithStyles<typeof styles> { }

export default withStyles(styles)(Footer);
