import React from 'react';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '450px',
      margin: '0 auto'
    },
    formGrid: {
      height: '400px',
      width: '100%'
    },
    submitButton: {
      backgroundColor: '#424B4D'
    }
  });

const ContactPage: React.SFC<ContactPageProps> = ({ classes }) => (
  <Layout pageTitle="Contact">
    <SEO title="Contact" />
    <Helmet>
      <meta name="referrer" content="no-referrer-when-downgrade"/>
    </Helmet>
    <form className={classes.container} noValidate autoComplete="off" action="https://formspree.io/studio@stilsongreene.com" method="POST" encType="multipart/form-data">
      <Grid className={classes.formGrid} container justify="center" alignItems="center">
        <Grid item xs>
          <input type="hidden" name="_subject" value="Contact form submitted" />
          <input type="hidden" name="_gotcha" value=""></input>
          <input type="hidden" name="_next" value="https://stilsongreene.com/submitted/"></input>
          <TextField
            label="Name"
            name="name"
            type="text"
            placeholder="Enter Name"
            fullWidth
            variant="filled"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Email"
            name="_replyto"
            type="email"
            placeholder="Enter Email"
            fullWidth
            variant="filled"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Message"
            name="message"
            placeholder="Enter Your Message"
            fullWidth
            multiline
            rows={3}
            rowsMax={10}
            variant="filled"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            className={classes.submitButton}
            type="submit"
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  </Layout>
);

interface ContactPageProps extends WithStyles<typeof styles> {

}

export default withStyles(styles)(ContactPage);
