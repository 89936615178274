import React from 'react';
import cn from 'classnames';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';

import { pages } from '../pages/index';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      paddingTop: '20px',
      paddingBottom: '16px',
      width: '100%',
    },
    headerContents: {
      textTransform: 'uppercase',
      width: '100%',
      fontWeight: 500,
      fontSize: '12px'
    },
    headerImage: {
      width: '90px',
      marginRight: '15px'
    },
    headerLinks: {
      marginTop: '15px'
    },
    pageTitle: {
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '30px'
    }
  });

import Grid from '@material-ui/core/Grid';

import logo from '../images/SG-MainLogo.svg';

const Header: React.SFC<HeaderProps> = ({ pageTitle, classes }) => (
  <div className={cn('Site-header', classes.header)} role="navigation">
    <Grid className={classes.headerContents} container justify="center" spacing={8} wrap="nowrap">
      <Grid item>
        <AniLink swipe direction="down" to="/">
          <img className={classes.headerImage} src={logo} alt="Stilson Greene: Graphic Design &amp; Illustration" />
        </AniLink>
      </Grid>
      <Grid item>
        <Grid className={classes.headerLinks} container spacing={8}>
          {pages.map(page => (
            <Grid key={page.to} item>
              <AniLink fade to={page.to}>{page.title}</AniLink>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
    <Grid className={classes.pageTitle} container justify="center">
      <Grid item>{pageTitle}</Grid>
    </Grid>
  </div>
);

interface HeaderProps extends WithStyles<typeof styles> {
  pageTitle: string;
}

export default withStyles(styles)(Header);
