import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import LayoutMain from '../components/layoutMain';
import Grid from '@material-ui/core/Grid';
import SEO from '../components/seo';

import logo from '../images/SG-MainLogo.svg';

export const pages = [
  { to: '/about/', title: 'About' },
  { to: '/logos/', title: 'Logos' },
  { to: '/designs/', title: 'Designs' },
  { to: '/illustrations/', title: 'Illustrations' },
  { to: '/links/', title: 'Links' },
  { to: '/contact/', title: 'Contact' },
];

const IndexPage: React.SFC = () => (
  <LayoutMain>
    <SEO
      title=""
      keywords={[`stilson`, `greene`, `graphic design`, `illustration`, `leesburg`, `virginia`, `award winning`, `batman`]}
    />
    <Grid item xs={12} style={{ textAlign: 'center', padding: '50px' }}>
      <img width={295} height={332} src={logo} alt="Stilson Greene: Graphic Design &amp; Illustration" />
    </Grid>
    <Grid item xs={12} style={{textTransform: 'uppercase'}}>
      <Grid container justify="center" spacing={16} style={{width: '100%', margin: 0}}>
        {pages.map((page) => (
          <Grid key={page.to} item>
            <AniLink swipe direction="up" to={page.to}>{page.title}</AniLink>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </LayoutMain>
);

export default IndexPage;
