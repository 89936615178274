import React from 'react';
import cn from 'classnames';

import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Footer from './footer';
import './layout.css';

const styles = (theme: Theme) => createStyles({
  layoutMain: {
    fontWeight: 500,
    margin: '30px auto 90px',
  }
});

const LayoutMain: React.SFC<LayoutProps> = ({ classes, children }) => (
  <>
    <Grid
      container
      className={cn('Site-content', classes.layoutMain)}
      justify="center"
      role="main"
    >
      {children}
    </Grid>
    <Footer />
  </>
);

interface LayoutProps extends WithStyles<typeof styles> {
  children: any;
}

export default withStyles(styles)(LayoutMain);
