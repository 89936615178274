import React from 'react';
import cn from 'classnames';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';

import Header from './header';
import Footer from './footer';

import './layout.css';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      width: '100%',
      margin: `0 auto`,
      paddingTop: '15px',
      paddingBottom: '25px',
      
    },
    paper: {
      margin: 'auto',
      maxWidth: '775px'
    },
  });

const Layout: React.SFC<LayoutProps> = ({ classes, pageTitle, children }: LayoutProps) => (
  <>
    <Header pageTitle={pageTitle} />
    <div className={cn('Site-content', classes.container)} role="main">
      <div className={classes.paper}>
        {children}
      </div>
    </div>
    <Footer />
  </>
);

interface LayoutProps extends WithStyles<typeof styles> {
  pageTitle: string;
  children: any;
}

export default withStyles(styles)(Layout);
